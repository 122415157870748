<template>
  <div class="publishDes" style="margin: 50px 28px 20px">
    <p>发布人：{{ payInfo.school_name }}</p>
    <p>
      缴费单编号：{{ payInfo.payment_id }}
      <span v-if="payInfo.bank_type != 4" class="copy" @click="copyNum">&nbsp;复制</span>
    </p>
    <p v-if="payInfo.bank_type == 4">客户编号: {{ payInfo.custom_id }}
      <span class="copy" @click="copyBocNum">&nbsp;复制</span>
    </p>

    <p>发布时间：{{ payInfo.create_time }}</p>
    <p v-if="payType > 1">缴费时间：{{ payInfo.update_time }}</p>
  </div>

  <div class="paymentDetail">
    <van-cell-group inset>
      <van-cell :value="payInfo.title" />
      <van-cell
        v-for="inf in payInfo.item"
        :key="inf.item_name"
        :title="inf.item_name"
        :value="'¥' + inf.payment"
      />
      <van-cell title="合计:" :value="'¥' + payInfo.price" />
    </van-cell-group>
  </div>
  
  <div v-if="payType == 1" style="margin: 60px 16px">
    <van-button v-if="payInfo.bank_type == 4" round block type="primary" @click="bocPay">
      复制客户编号并打开中行app进行缴费
    </van-button>

    <van-button v-else round block type="primary" @click="showPopup">
      立即缴费
    </van-button>
  </div>
  <!-- <div  style="margin:0px 16px">
      <van-button round block type="primary" @click="goback">
        返回缴费列表
      </van-button>
    </div> -->
  <van-popup v-model:show="show" closeable position="bottom" :style="{ height: '50%' }">
    <div style="margin-top: 20px">
      <van-cell value=" " />
      <van-cell :value="'付款给：' +payInfo.school_name" />
      <van-cell title="缴费名称" :value="payInfo.title" />
      <van-cell title="缴费金额" :value="'¥' + payInfo.price" />
    </div>

    <div style="margin: 50px 16px">
      <van-button round block type="primary" @click="toBankpPay"> 确认付款 </van-button>
    </div>
  </van-popup>
  <div id="container"></div>
  <van-dialog
    v-model:show="showAlert"
    title="添加信息"
    show-cancel-button
    :before-close="beforeClose"
  >
    <van-cell-group inset>
      <!-- <van-field
        v-model="realname"
        maxlength="12"
        :rules="[{ required: true }]"
        clearable
        label="真实姓名"
        placeholder="请输入缴费用户姓名"
      /> -->
      <van-field
        v-model="idcardNo"
        maxlength="18"
        label="身份证号"
        type="digit"
        :rules="[{ required: true }]"
        placeholder="输入身份证号"
      />
      <van-cell
        title=""
        size="large"
        label="山东省财政厅非税系统要求缴费用户必须填写身份证号码，用于核对缴费用户有效性，请认真填写。"
      />
    </van-cell-group>
  </van-dialog>
</template>

<script>
import { reactive, onMounted, ref, watch } from "vue";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import api from "../service/api";
import { checkIdNum, isAndroid, setCookie } from "../common/js/utils";
import useClipboard from "vue-clipboard3";
import { getStorage, setStorage } from "../common/js/storeUtil";

export default {
  setup() {
    const show = ref(false);
    const payType = ref(1);
    const Route = useRoute(); //获取值时使用
    const router = useRouter();
    // const formjs = ref("");
    const realname = ref("");
    const idcardNo = ref("");
    const showAlert = ref(false);
    const { toClipboard } = useClipboard();

    const payInfo = reactive({
      item: [],
      payment_id: "",
      create_time: "",
      update_time: "",
      teacher_id: "",
      school_id: "",
      school_name: "",
      title: "",
      name: "",
      price: "",
      bank_type: "",
      charge_teacher_id:"",
      custom_id:"",
    });
    const onClickLeft = () => {
      router.go(-1);
    };
    const showPopup = () => {
      show.value = true;
    };

    const toBankpPay = () => {
      show.value = false;
      if (payInfo.bank_type == 4) {
        // 4 中行支付(欠缴模式)
        bocPay();
      }
      if (payInfo.bank_type == 3) {
        // 3 银联支付
        uniPay();
      }
      if (payInfo.bank_type == 1) {
        //1 农行支付
        abcPay();
      }
      if (payInfo.bank_type ==2) {
        // 2 非税
       if(!getStorage("idcardNo")){
          showAlert.value = true;
       } else{
        idcardNo.value = getStorage("idcardNo");
        createCEBPayOrder();
       }

      }
    };

    onMounted(() => {
      /* eslint-disable */
      //setTitle("缴费详情");
      payType.value = Route.query.payType;
      onLoad();
    });
    watch(() => {
      // Toast(  router.currentRoute.value.fullPath);
      if (router.currentRoute.value.fullPath == "/payment") {
        router.go(-2);
      }
    });
    // let store = useStore();
    const beforeClose = (action) => {
      if (action === "confirm") {
        // if (!checkName(realname.value)) {
        //   Toast("姓名只能是2-8位汉字");
        //   return;
        // }
        if (!checkIdNum(idcardNo.value)) {
          Toast("请认真核对身份证号");
          return;
        }
        createCEBPayOrder();
      } else {
        showAlert.value = false;
      }
    };
    function checkName(name) {
      var reg = /^[\u4e00-\u9fa5]{2,8}$/;
      return reg.test(name);
    }

    /**
     * 中行支付
     */
    const bocPay = () => {
      api
        .createTeacherBocScheme({
          d_charge_id: payInfo.charge_teacher_id,
          type:2,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            copyBocNum(payInfo.custom_id);
            // window.location.href = "bocmbankpsn://startwith/LrAT2PfnMT";
            let url = res.data.result.boc_url;
            let segments = url.split("/");
            let schoolCode = segments[segments.length - 1];
            window.location.href = "bocmbankpsn://startwith/" + schoolCode;
          } else {
            Toast(res.data);
          }
        })
        // .catch((err) => {
        //   Toast(err.message);
        // });
    };

    const copyNum = async () => {
      try {
        await toClipboard(payInfo.payment_id);
        Toast("复制成功");
      } catch (e) {
        Toast("复制失败");
      }
    };

    
    const copyBocNum = async () => {
      try {
        await toClipboard(payInfo.custom_id);
        Toast("复制成功");
      } catch (e) {
        Toast("复制失败,请手动复制缴费编号后打开中国银行app进行输入");
      }
    };

    /**
     * 非税订单创建
     */
    const createCEBPayOrder = () => {
      api
        .createCEBPayOrder({
          teacher_charge_id: payInfo.charge_teacher_id,
          payment: payInfo.price,
          id_card: idcardNo.value,
          type: 2,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            showAlert.value = false;
            // if (checkName(realname.value) && checkIdNum(idcardNo.value)) {
            //   setStorage("realname", realname.value);
            //   setStorage("idcardNo", idcardNo.value);
            // }
            if (checkIdNum(idcardNo.value)) {
              setStorage("idcardNo", idcardNo.value);
            }
            
            window.location.replace(res.data.result.url);
          }
          else {
            showAlert.value = false;
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };
    /**
     * 农行支付
     */
    const abcPay = () => {
      api
        .createTeacherAbcOrder({
          teacher_charge_id: payInfo.charge_teacher_id,
          payment: payInfo.price,
          type: 1,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            window.location.replace(res.data.result.url);
          } else {
            Toast(res.data);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };

    /**
     * 云闪付支付
     */
    const uniPay = () => {
      api
        .createUnionPayOrder({
          orderNo: Route.query.payment_id,
          money: payInfo.price,
          school_id: payInfo.school_id,
          type: 1,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            document.getElementById("container").innerHTML = res.data.result;
            document.forms[0].submit();
            history.replaceState({}, "", "https://ystcharge.kunyun.cn/payment");
          } else {
            Toast(res.data);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };

    const onLoad = () => {
      api
        .getTeacherPaymentDetail({
          t_charge_teacher_id: Route.query.t_charge_teacher_id,
          // type: Route.query.payType,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            payInfo.charge_teacher_id=  res.data.result.id;
            payInfo.payment_id = res.data.result.payment_no;
            payInfo.name = res.data.result.operation;
            payInfo.create_time = res.data.result.create_at;
            payInfo.update_time = res.data.result.success_at;
            payInfo.teacher_id = res.data.result.teacher_id;
            payInfo.school_id = res.data.result.school_id;
            payInfo.title = res.data.result.t_charge_name;
            payInfo.price = res.data.result.payment;
            payInfo.bank_type = res.data.result.school_bank_type;
            payInfo.item = res.data.result.item;
            payInfo.custom_id = res.data.result.custom_id;
            //payInfo.school_name = "付款给：" + res.data.result.school_name;
            payInfo.school_name = res.data.result.school_name;

            // payInfo.payment_no = res.data.result.payment_no;
            // payInfo.charge_no = res.data.result.charge_no;
            // payType.value = res.data.result.status;

          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };

    return {
      show,
      payType,
      payInfo,
      showPopup,
      toBankpPay,
      onClickLeft,
      // formjs,
      showAlert,
      beforeClose,
      idcardNo,
      realname,
      copyNum,
      bocPay,
    };
  },
};
</script>

<style lang="less">
.publishDes {
  display: block;
  margin-left: 10px;
  line-height: 20px;
  font-size: 14px;
  p {
    font-size: 14px;
    padding: 2px 0;
    text-align: left;

    .copy {
      color: deepskyblue;
      font-size: 14px;
    }
  }
}
</style>
